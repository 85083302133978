<template>
        <div class="mail-template" style="display: flex;flex-wrap: wrap;">

            <div class="left-content" style="width: 45%;background-color: #fff!important;position: relative;">
                <div style="padding: 30px;">
                    <img src="@/assets/images/insiderbroker.svg" alt="logo" width="60" style="width: 60px;">
                    <h1 style="margin-top:22%;font-size: 40px;color: #183F68;font-family: 'Hurme Geometric Sans Bold';">Welcome to InsiderBroker</h1>
                    <h2 style="font-family: 'Hurme Geometric Sans Bold';font-size: 30px;margin: 0;color:#2B2B2B;">Work in Progress,</h2>
                    <h2 style="font-family: 'Hurme Geometric Sans Bold';font-size: 30px;margin: 0;color:#2B2B2B;">Stay Tuned!</h2>

                    <img src="@/assets/images/center.png" class="center-image" alt="right-background" width="100%" style="display: none;">

                    <div class="contact" style="position: absolute;left: 30px;bottom:20px">
                        <p style="color: #223E65; font-size:17px;">Contact Us</p>
                        <p style="color: #223E65;font-size:16px;margin-top: 0;opacity: 0.8;">
                            8901 Woodbine Ave, Unit 118 Markham, ON L3R 9Y4
                        </p>
                        <p style="color: #223E65;font-size:16px;margin: 0;opacity: 0.8;">905.503.9545 insiderbroker@cornerstonemarketing.ca</p>
                    </div>
                </div>
            </div>
            <div class="right-background" style="width: 55%;height: 100vh;">
                <img src="@/assets/images/ddd.png" alt="right-background" width="100%">
            </div>

        </div>
</template>

<style>
    @font-face {
        font-family: 'Hurme Geometric Sans SemiBold';
        src: url('@/assets/fonts/HurmeGeometricSans1SemiBold.b28e5dfc.otf');
    }

    @font-face {
        font-family: 'Hurme Geometric Sans Light';
        src: url('@/assets/fonts/HurmeGeometricSans1Light.5988d745.otf');
    }

    @font-face {
        font-family: 'Hurme Geometric Sans';
        src: url('@/assets/fonts/HurmeGeometricSans1.9a1c38b1.otf');
    }

    @font-face {
        font-family: 'Hurme Geometric Sans Bold';
        src: url('@/assets/fonts/HurmeGeometricSans1\ Bold.otf');
    }

    body .mail-template{
        margin: 0;
        padding: 0;
        overflow: hidden;
        font-family: 'Hurme Geometric Sans';
    }

    @media(max-width: 768px){
        body .mail-template{
            overflow: auto;
        }
        .mail-template .left-content , .mail-template .right-background, .mail-template .right-background img {
            width: 100%!important;
        }
        .mail-template .contact{
            position: relative!important;
            left: unset!important;
            bottom: unset!important;
        }
        .mail-template .contact p:first-child{
            font-size: 14px;
        }
        .mail-template .contact p:not(.mail-template .contact p:first-child){
            font-size: 13px!important;
        }
        .mail-template .right-background{
            display: none!important;
        }
        .mail-template .center-image{
            display: block!important;
        }
        .mail-template .left-content h1{
            font-size: 29px!important;
            text-align: center;
            margin-top: 30px!important;
        }
        .mail-template .left-content h2{
            font-size: 20px!important;
            text-align: center;
            font-family: 'Hurme Geometric Sans SemiBold'!important;
        }
    }
</style>

<script>
    export default {
        name: 'SuccessTemplate',
    }
</script>