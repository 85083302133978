<template>
  <SignupPage />
</template>

<script>
import SignupPage from './components/Signup.vue'

export default {
  name: 'App',
  components: { SignupPage }
}
</script>