<template>
  <div class="signup" v-if="!isSibmited">
    <div class="container-fluid p-0">
      <div class="row">

        <div class="col-lg-4 col-12 p-0">
          <div class="signup-backgroud">
            <div class="header">
              <img src="@/assets/images/insiderbroker-white.svg" width="60" alt="insider-logo">
            </div>
            <h1>Become an InsiderBroker</h1>
            <p>InsiderBroker offers exclusive access to real estate developments to industry professionals.</p>
            <img class="bottom-image" src="@/assets/images/Mask.png" alt="backgroud">
          </div>
        </div>

        <div class="col-lg-8 col-12 p-0">
          <div class="form-box">
            <form>
              <div class="row">
                <div class="col-lg-6 col-12">
                  <div class="information">
                    <div class="col-12">
                      <h3>General Information</h3>
                    </div>

                    <div class="row">

                      <div class="col-lg-6 col-12">
                        <div class="form" :class="{ error: v$.form.first_name.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.05" height="15.037" viewBox="0 0 20.05 15.037">
                            <g id="Group_1850" data-name="Group 1850" opacity="0.6">
                              <path id="Path_1250" data-name="Path 1250" d="M17.752,0H2.3A2.3,2.3,0,0,0,0,2.3V12.74a2.3,2.3,0,0,0,2.3,2.3H17.752a2.3,2.3,0,0,0,2.3-2.3V2.3A2.3,2.3,0,0,0,17.752,0ZM6.265,3.342A2.089,2.089,0,1,1,4.177,5.43,2.091,2.091,0,0,1,6.265,3.342Zm3.759,7.728A.627.627,0,0,1,9.4,11.7H3.133a.627.627,0,0,1-.627-.627v-.418a2.3,2.3,0,0,1,2.3-2.3H7.727a2.3,2.3,0,0,1,2.3,2.3Zm6.892.627H12.322a.627.627,0,0,1,0-1.253h4.595a.627.627,0,1,1,0,1.253Zm0-3.342H12.322a.627.627,0,0,1,0-1.253h4.595a.627.627,0,1,1,0,1.253Zm0-3.342H12.322a.627.627,0,0,1,0-1.253h4.595a.627.627,0,1,1,0,1.253Zm0,0" fill="#2d3c5f"/>
                            </g>
                          </svg>
                          <input type="text" id="first-name" v-model="v$.form.first_name.$model" required autocomplete="off">
                          <label for="first-name">First name</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.first_name.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-6 col-12">
                        <div class="form" :class="{ error: v$.form.last_name.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.05" height="15.037" viewBox="0 0 20.05 15.037">
                            <g id="Group_1850" data-name="Group 1850" opacity="0.6">
                              <path id="Path_1250" data-name="Path 1250" d="M17.752,0H2.3A2.3,2.3,0,0,0,0,2.3V12.74a2.3,2.3,0,0,0,2.3,2.3H17.752a2.3,2.3,0,0,0,2.3-2.3V2.3A2.3,2.3,0,0,0,17.752,0ZM6.265,3.342A2.089,2.089,0,1,1,4.177,5.43,2.091,2.091,0,0,1,6.265,3.342Zm3.759,7.728A.627.627,0,0,1,9.4,11.7H3.133a.627.627,0,0,1-.627-.627v-.418a2.3,2.3,0,0,1,2.3-2.3H7.727a2.3,2.3,0,0,1,2.3,2.3Zm6.892.627H12.322a.627.627,0,0,1,0-1.253h4.595a.627.627,0,1,1,0,1.253Zm0-3.342H12.322a.627.627,0,0,1,0-1.253h4.595a.627.627,0,1,1,0,1.253Zm0-3.342H12.322a.627.627,0,0,1,0-1.253h4.595a.627.627,0,1,1,0,1.253Zm0,0" fill="#2d3c5f"/>
                            </g>
                          </svg>
                          <input type="text" id="last-name" v-model="v$.form.last_name.$model" required autocomplete="off">
                          <label for="last-name">Last name</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.last_name.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.phone.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <g id="Iconly_Bold_Call" data-name="Iconly/Bold/Call" opacity="0.6">
                              <g id="Call" transform="translate(0 0)">
                                <path id="Call-2" data-name="Call" d="M16.167,12.379a2.279,2.279,0,0,0-1.674.44c-.482.281-1.4,1.024-1.923.834a11.932,11.932,0,0,1-6.313-6.16c-.193-.536.547-1.458.825-1.946a2.335,2.335,0,0,0,.436-1.685A13.7,13.7,0,0,0,5.189.615,1.912,1.912,0,0,0,4.021,0C2.52-.063.844,1.94.549,2.419-.187,3.441-.183,4.8.562,6.45c1.8,4.428,8.586,11.112,13.031,12.974A5.371,5.371,0,0,0,15.837,20a2.919,2.919,0,0,0,1.751-.549c.381-.219,2.466-1.979,2.411-3.521a1.941,1.941,0,0,0-.608-1.166,13.8,13.8,0,0,0-3.224-2.385" fill="#2d3c5f"/>
                              </g>
                            </g>
                          </svg>
                          <input type="text" id="phone-number" v-model="v$.form.phone.$model" required autocomplete="off">
                          <label for="phone-number">Phone number</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.phone.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.email.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                            <g id="Iconly_Bold_Message" data-name="Iconly/Bold/Message" opacity="0.6">
                              <g id="Message" transform="translate(0)">
                                <path id="Message-2" data-name="Message" d="M14.939,18H5.06A5.061,5.061,0,0,1,0,12.95V5.05A5.061,5.061,0,0,1,5.06,0h9.879a5.091,5.091,0,0,1,3.58,1.481A5.012,5.012,0,0,1,20,5.05v7.9A5.061,5.061,0,0,1,14.939,18ZM4.034,5.246A.733.733,0,0,0,3.5,5.47a.764.764,0,0,0-.071,1l.131.13L8.11,10.15a3.129,3.129,0,0,0,1.95.68,3.18,3.18,0,0,0,1.958-.68L16.53,6.54l.08-.08a.774.774,0,0,0-.012-1,.831.831,0,0,0-.528-.26h-.042a.76.76,0,0,0-.519.2L11,9a1.565,1.565,0,0,1-1,.36A1.592,1.592,0,0,1,9,9L4.5,5.4A.778.778,0,0,0,4.034,5.246Z" fill="#223e65"/>
                              </g>
                            </g>
                          </svg>
                          <input type="text" id="email-address" v-model="v$.form.email.$model" required autocomplete="off">
                          <label for="email-address">Email address</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.email_confirmation.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                            <g id="Iconly_Bold_Message" data-name="Iconly/Bold/Message" opacity="0.6">
                              <g id="Message" transform="translate(0)">
                                <path id="Message-2" data-name="Message" d="M14.939,18H5.06A5.061,5.061,0,0,1,0,12.95V5.05A5.061,5.061,0,0,1,5.06,0h9.879a5.091,5.091,0,0,1,3.58,1.481A5.012,5.012,0,0,1,20,5.05v7.9A5.061,5.061,0,0,1,14.939,18ZM4.034,5.246A.733.733,0,0,0,3.5,5.47a.764.764,0,0,0-.071,1l.131.13L8.11,10.15a3.129,3.129,0,0,0,1.95.68,3.18,3.18,0,0,0,1.958-.68L16.53,6.54l.08-.08a.774.774,0,0,0-.012-1,.831.831,0,0,0-.528-.26h-.042a.76.76,0,0,0-.519.2L11,9a1.565,1.565,0,0,1-1,.36A1.592,1.592,0,0,1,9,9L4.5,5.4A.778.778,0,0,0,4.034,5.246Z" fill="#223e65"/>
                              </g>
                            </g>
                          </svg>
                          <input type="text" id="confirm-email-address" v-model="v$.form.email_confirmation.$model" required autocomplete="off">
                          <label for="confirm-email-address">Confirm Email address</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.email_confirmation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.password.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18.097" height="18.097" viewBox="0 0 18.097 18.097">
                            <g id="Iconly_Bold_Password" data-name="Iconly/Bold/Password" opacity="0.6">
                              <g id="Password">
                                <path id="Password-2" data-name="Password" d="M5.127,18.1C2.06,18.1,0,15.946,0,12.744V5.354C0,2.152,2.06,0,5.127,0h7.844C16.037,0,18.1,2.152,18.1,5.354v7.39c0,3.2-2.06,5.353-5.126,5.353ZM11.7,9.727h1.2v1a.678.678,0,0,0,1.357,0V9.048a.679.679,0,0,0-.678-.678H8.432a2.354,2.354,0,1,0,0,1.357h1.911v1a.679.679,0,0,0,1.358,0v-1h0ZM5.193,9.049a1,1,0,1,1,1,1A1,1,0,0,1,5.193,9.049Z" fill="#183f68"/>
                              </g>
                            </g>
                          </svg>
                          <input type="Password" id="password" v-model="v$.form.password.$model" required autocomplete="off">
                          <label for="password">Password</label>
                          
                          <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.password_confirmation.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18.097" height="18.097" viewBox="0 0 18.097 18.097">
                            <g id="Iconly_Bold_Password" data-name="Iconly/Bold/Password" opacity="0.6">
                              <g id="Password">
                                <path id="Password-2" data-name="Password" d="M5.127,18.1C2.06,18.1,0,15.946,0,12.744V5.354C0,2.152,2.06,0,5.127,0h7.844C16.037,0,18.1,2.152,18.1,5.354v7.39c0,3.2-2.06,5.353-5.126,5.353ZM11.7,9.727h1.2v1a.678.678,0,0,0,1.357,0V9.048a.679.679,0,0,0-.678-.678H8.432a2.354,2.354,0,1,0,0,1.357h1.911v1a.679.679,0,0,0,1.358,0v-1h0ZM5.193,9.049a1,1,0,1,1,1,1A1,1,0,0,1,5.193,9.049Z" fill="#183f68"/>
                              </g>
                            </g>
                          </svg>
                          <input type="Password" id="confirm-password" v-model="v$.form.password_confirmation.$model" required autocomplete="off">
                          <label for="confirm-password">Confirm Password</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.password_confirmation.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="information">
                    <div class="col-12">
                      <h3>Brokerage Information</h3>
                    </div>

                    <div class="row">

                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.brokerage_name.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
                            <g id="Iconly_Bold_3_User" data-name="Iconly/Bold/3 User" opacity="0.6">
                              <g id="_3_User" data-name="3 User">
                                <path id="_3_Friends" data-name="3 Friends" d="M4.588,13.28c0-2.177,2.911-2.741,6.361-2.741,3.431,0,6.36.544,6.36,2.721S14.4,16,10.949,16C7.518,16,4.588,15.456,4.588,13.28Zm14.5.4A4.453,4.453,0,0,0,16.431,9.3a.045.045,0,0,1-.029-.047.038.038,0,0,1,.032-.026,15.49,15.49,0,0,1,3.244.284,2.76,2.76,0,0,1,2.131,1.3,1.927,1.927,0,0,1,0,1.67c-.461,1-1.944,1.319-2.521,1.4l-.027,0A.183.183,0,0,1,19.086,13.684Zm-16.375.205c-.576-.083-2.06-.4-2.52-1.4a1.913,1.913,0,0,1,0-1.669,2.756,2.756,0,0,1,2.13-1.305,15.644,15.644,0,0,1,3.244-.283.037.037,0,0,1,.032.025.046.046,0,0,1-.028.048,4.449,4.449,0,0,0-2.656,4.38.182.182,0,0,1-.176.208ZM6.74,4.233a4.209,4.209,0,1,1,8.419,0,4.209,4.209,0,1,1-8.419,0Zm9.823,3.609a3.4,3.4,0,0,1-.483-.047.159.159,0,0,1-.106-.245A5.841,5.841,0,0,0,15.889.939a.106.106,0,0,1-.016-.111.13.13,0,0,1,.083-.049,3.507,3.507,0,0,1,.708-.073A3.576,3.576,0,0,1,20.088,5.22a3.51,3.51,0,0,1-3.429,2.623ZM1.912,5.22A3.576,3.576,0,0,1,5.336.706a3.53,3.53,0,0,1,.709.073.135.135,0,0,1,.082.049.1.1,0,0,1-.016.11A5.84,5.84,0,0,0,6.027,7.55a.159.159,0,0,1-.107.245,3.281,3.281,0,0,1-.483.047h-.1A3.512,3.512,0,0,1,1.912,5.22Z" fill="#303c5c"/>
                              </g>
                            </g>
                          </svg>
                          <input type="text" id="brokerage-name" v-model="v$.form.brokerage_name.$model" required>
                          <label for="brokerage-name">Brokerage name</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.brokerage_name.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>
  
                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.address.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
                            <g id="Iconly_Bold_Location" data-name="Iconly/Bold/Location" opacity="0.6">
                              <g id="Location" transform="translate(0 0)">
                                <path id="Location-2" data-name="Location" d="M8.5,20a1.358,1.358,0,0,1-.734-.247,21.513,21.513,0,0,1-5.54-5.141A10.384,10.384,0,0,1,0,8.318,8.168,8.168,0,0,1,2.5,2.434,8.53,8.53,0,0,1,8.493,0,8.423,8.423,0,0,1,17,8.318a10.39,10.39,0,0,1-2.23,6.294,21.92,21.92,0,0,1-5.541,5.141A1.319,1.319,0,0,1,8.5,20ZM8.493,5.777a2.8,2.8,0,0,0-2.8,2.8,2.712,2.712,0,0,0,.821,1.954,2.823,2.823,0,0,0,4.79-1.954,2.824,2.824,0,0,0-2.813-2.8Z" fill="#303c5c"/>
                              </g>
                            </g>
                          </svg>
                          <vue-google-autocomplete id="address" v-model="v$.form.address.$model" classname="form-control" required></vue-google-autocomplete>
                          <label for="address">Address</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.address.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-6 col-12">
                        <div class="form" :class="{ error: v$.form.city.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="19.741" height="19.741" viewBox="0 0 19.741 19.741">
                            <g id="Group_1916" data-name="Group 1916" opacity="0.6">
                              <g id="Group_1915" data-name="Group 1915" transform="translate(0)">
                                <path id="Path_1284" data-name="Path 1284" d="M196.012.578A.578.578,0,0,0,195.434,0h-3.856A.578.578,0,0,0,191,.578v.964h5.012Z" transform="translate(-183.636)" fill="#303c5c"/>
                                <path id="Path_1285" data-name="Path 1285" d="M19.162,85.885h-.231V80.295a.578.578,0,0,0-.32-.517L17.2,79.07v6.816H16.039V77.21a.578.578,0,0,0-.258-.481l-3.47-2.313a.578.578,0,0,0-.9.481V85.885H10.256V74.9a1.735,1.735,0,0,1,2.7-1.443l.58.386V70.578A.578.578,0,0,0,12.955,70H6.786a.578.578,0,0,0-.578.578v.655h.964a1.737,1.737,0,0,1,1.735,1.735V85.885H7.75V72.969a.578.578,0,0,0-.578-.578H3.7a.578.578,0,0,0-.578.578V80.68H4.472a1.737,1.737,0,0,1,1.735,1.735v3.47H5.051v-3.47a.578.578,0,0,0-.578-.578H1.388a.578.578,0,0,0-.578.578v3.47H.578a.578.578,0,1,0,0,1.157H19.162a.578.578,0,1,0,0-1.157Zm-6.015-6.747a.578.578,0,0,1,1.157,0v1.157a.578.578,0,0,1-1.157,0Zm0,3.47a.578.578,0,0,1,1.157,0v1.157a.578.578,0,0,1-1.157,0Zm-7.133-3.47a.578.578,0,1,1-1.157,0V77.981a.578.578,0,1,1,1.157,0Zm0-3.47a.578.578,0,1,1-1.157,0V74.511a.578.578,0,0,1,1.157,0ZM2.352,84.536a.578.578,0,0,1,1.157,0v1.349H2.352Z" transform="translate(0 -67.301)" fill="#303c5c"/>
                              </g>
                            </g>
                          </svg>
                          <vue-google-autocomplete
                            id="city"
                            classname="form-control"
                            types="(cities)"
                            autocomplete="off"
                            required
                            v-model="v$.form.city.$model"
                          >
                          </vue-google-autocomplete>
                          <label for="city">City</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.city.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-6 col-12">
                        <div class="form" :class="{ error: v$.form.province.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.049" height="15.78" viewBox="0 0 20.049 15.78">
                            <g id="Group_1917" data-name="Group 1917" transform="translate(-1.999 -4.969)" opacity="0.6">
                              <g id="Layer_52" data-name="Layer 52" transform="translate(1.999 4.969)">
                                <path id="Path_1286" data-name="Path 1286" d="M14.888,19.469,9.16,20.615V6.294l5.728-1.146ZM7.728,6.223,2.888,4.991A.711.711,0,0,0,2,5.707V18.6a.716.716,0,0,0,.544.716l5.184,1.275Zm13.776.2L16.32,5.149V19.512l4.84,1.232a.807.807,0,0,0,.888-.716V7.139a.716.716,0,0,0-.544-.716Z" transform="translate(-1.999 -4.969)" fill="#303c5c"/>
                              </g>
                            </g>
                          </svg>
                          <vue-google-autocomplete
                            id="province"
                            classname="form-control"
                            autocomplete="off"
                            types="(cities)"
                            required
                            v-model="v$.form.province.$model"
                          >
                          </vue-google-autocomplete>
                          <label for="province">Province</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.province.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form" :class="{ error: v$.form.postal_code.$errors.length }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18.785" height="18.785" viewBox="0 0 18.785 18.785">
                            <g id="Group_1918" data-name="Group 1918" transform="translate(-6 -6)" opacity="0.6">
                              <path id="Path_1287" data-name="Path 1287" d="M11,29.905a2.139,2.139,0,0,0,2.09,2.184H23.782a2.141,2.141,0,0,0,2.09-2.184V18H11Zm2.348-9.557H23.524a.391.391,0,1,1,0,.783H13.348a.391.391,0,1,1,0-.783Z" transform="translate(-3.043 -7.304)" fill="#303c5c"/>
                              <path id="Path_1288" data-name="Path 1288" d="M23.737,6H7.045A1.048,1.048,0,0,0,6,7.049v1.82A1.047,1.047,0,0,0,7.045,9.914H23.737a1.048,1.048,0,0,0,1.049-1.045V7.049A1.049,1.049,0,0,0,23.737,6Z" transform="translate(0 0)" fill="#303c5c"/>
                            </g>
                          </svg>
                          <input type="text" id="postal-code" v-model="v$.form.postal_code.$model" required autocomplete="off">
                          <label for="postal-code">Postal code</label>

                          <div class="input-errors" v-for="(error, index) of v$.form.postal_code.$errors" :key="index">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-12 text-center">
                  <div class="btn">
                    <button @click="onSubmit($event)">GET STARTED!</button>
                    <p>By Creating An Account, You Agree To The Terms & Conditions And Privacy Policy.</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SuccessTemplate v-else/>
  <OverlayLoader v-show="isLoading" />
</template>

<script>
  import useVuelidate from '@vuelidate/core';
  import { required, email, minLength, sameAs } from '@vuelidate/validators';
  
  import { signup } from "@/services/axios/signup.service";

  import VueGoogleAutocomplete from "vue-google-autocomplete";
  import OverlayLoader from './overlay/OverlayLoader.vue';
  import SuccessTemplate from './successTemplate/SuccessTemplate.vue'
  
  export default {
    name: 'SignupPage',
    components: { VueGoogleAutocomplete , OverlayLoader , SuccessTemplate },

    computed: {
      isLoading: {
        get(){
          return this.$store.state.isLoading;
        }
      }
    },

    data() {
      return {
        form: {
          first_name: '',
          last_name: '',
          email: '',
          email_confirmation: '',
          phone: '',
          brokerage_name: '',
          address: '',
          city: '',
          province: '',
          postal_code: '',
          password: '',
          password_confirmation: '',
        },

        isSibmited: false
      }
    },

    setup () {
      return { v$: useVuelidate() }
    },

    validations() {
      return {
        form: {
          first_name: {
            required,
          },
          last_name: {
            required,
          },
          email: {
            required, 
            email 
          },
          email_confirmation: {
            required, 
            sameAs: sameAs(this.form.email),
            email
          },
          phone: {
            required, 
          },
          brokerage_name: {
            required
          },
          address: {
            required
          },
          city: {
            required
          },
          province: {
            required
          },
          postal_code: {
            required
          },
          password: {
            required, 
            min: minLength(8)
          },
          password_confirmation: {
            required, 
            sameAs: sameAs(this.form.password),
            min: minLength(8)
          },
        },
      }
    },

    methods: {
      onSubmit(e) {
        e.preventDefault();

        this.v$.$touch();
          
        if(this.v$.$invalid){
          return;
        }

        const body = {
          ...this.form,
          avatar: null
        }

        delete body.email_confirmation;
          
        signup(body).then(res => {
          if(!res){
            return;
          }
          
          this.isSibmited = true;
          
        }).catch(e => {
          console.log(e);
        })
      },

    }

  }
</script>

<style>
  @import '../assets/style/bootstrap.css';

  body{
    margin: 0;
  }

  @font-face {
    font-family: 'Hurme Geometric Sans SemiBold';
    src: url('@/assets/fonts/HurmeGeometricSans1SemiBold.b28e5dfc.otf');
  }

  @font-face {
    font-family: 'Hurme Geometric Sans Light';
    src: url('@/assets/fonts/HurmeGeometricSans1Light.5988d745.otf');
  }

  @font-face {
    font-family: 'Hurme Geometric Sans';
    src: url('@/assets/fonts/HurmeGeometricSans1.9a1c38b1.otf');
  }
  
  .signup .signup-backgroud {
    background-color:  #00406B;
    height: 100%;
    min-height: 100vh;
    position: relative;
  }

  .signup .signup-backgroud .header{
    padding: 50px 50px 0 50px;
  }

  .signup .signup-backgroud h1{
    text-align: center;
    color: #fff;
    font-family: 'Hurme Geometric Sans SemiBold';
    font-size: 38px;
    margin-top: 40px;
    padding: 0 50px;
  }

  .signup .signup-backgroud p{
    text-align: center;
    color: #fff;
    font-family: 'Hurme Geometric Sans Light';
    font-size: 13px;
    opacity: 0.6;
    padding: 0 50px;
  }

  .signup .signup-backgroud .bottom-image{
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .signup .form-box {
    padding: 50px 25px;
  }

  .signup .form-box .btn {
    margin: 50px auto 20px auto;
  }

  .signup .form-box .btn button{
    background-color: #00406B;
    border-radius: 6px;
    color: #fff;
    font-family: 'Hurme Geometric Sans SemiBold';
    font-size: 16px;
    padding: 20px 0;
    border: none;
    cursor: pointer;
    width: 450px;
  }

  .signup .form-box .btn p {
    opacity: 0.6;
    color: #000;
    font-size: 12px;
    font-family: 'Hurme Geometric Sans Light';
  }

  .signup .form-box .information h3{
    color: #183F68;
    font-family: 'Hurme Geometric Sans SemiBold';
    font-size: 15px;
  }

  .signup .form-box .information .form {
    position: relative;
  }

  .signup .form-box .information .form input{
    border: 1px solid #fff!important;
  }

  .signup .form-box .information .form.error input{
    border: 1px solid red!important;
  }

  .signup .form-box .information .form svg{
    margin-bottom: -44px;
    margin-left: 12px;
  }

  .signup .form-box .information .form .error-msg{
    color: red;
    margin: 7px 0;
    font-size: 12px;
  }

  .signup .form-box .information .form .input-errors{
    display: none;
  }

  .signup .form-box .information .form label{
    font-size: 14px;
    color: #183F68;
    font-family: 'Hurme Geometric Sans';
    position: absolute;
    left: 50px;
    top: 39px;
    transition: 0.3s;
  }

  .signup .form-box .information .form input{
    padding: 25px 0 20px 50px;
    background-color: #4A84C10D;
    border: none;
    outline: none;
    color: #183F68;
    font-size: 14px;
    border-radius: 6px;
    width: 100%;
    font-family: 'Hurme Geometric Sans';
  }

  .signup .form-box .information .form .form-control::placeholder{
    opacity: 0;
  }

  .signup .form-box .information .form input:focus + label , 
  .signup .form-box .information .form input:valid + label{
    font-size: 12px;
    top: 25px;
    opacity: 0.5;
  }

  @media(max-width: 991px){ 
    .signup .signup-backgroud{
      min-height: unset;      
      padding-bottom: 100px;
    }
    .signup .signup-backgroud .bottom-image{
     display: none; 
    }
    .signup .form-box{
      margin-top: -50px;
      border-radius: 60px 60px 0px 0px;
      background-color: #fff;
    }
  }

  @media(max-width: 575px){ 
    .signup .form-box .btn button{
      width: 100%;
    }
  }
</style>