import axios from 'axios';
import store from '@/store'

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const toastConfig = {
      autoClose: 3000,
      position: toast.POSITION.BOTTOM_LEFT,
      type: toast.TYPE.ERROR,
      theme: toast.THEME.COLORED
}

const httpClient = axios.create({
      baseURL: 'https://portal.cornerstonemarketing.ca/',
      headers: {}
});

httpClient.interceptors.request.use((config) => {
      store.commit('mutationer', { isLoading: true })
      return config;
}, (error) => {
      return Promise.reject(error);
});

httpClient.interceptors.response.use(
      (response) => {
            setTimeout(() => {
                  store.commit('mutationer', { isLoading: false });
            }, 500);
            return response
      },
      (error) => {
            store.commit('mutationer', { isLoading: false });
            Promise.reject(error);
            toast(error?.response?.data?.message || error?.response?.data?.result?.message, toastConfig);
      }
);

export default httpClient;